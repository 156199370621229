import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function Stats() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#010322",
        }}
        className="section"
        data-parallax={true}
      >
        <Container fluid>
          <Row className="justify-content-center">
            {content.map((c) => (
              <Col lg={3} className={`px-5 mt-4`}>
                <a href={c.link} target="_blank">
                  <img
                    src={require(`assets/images/logo/${c.image}`)}
                    width={"100%"}
                    style={{ maxWidth: "400px" }}
                    alt="Finnovex"
                  />
                  <h4 className="text-center text-white mt-2 text-400">{c.date}</h4>
                  <h4 className="text-center text-white mt-0 text-400">{c.location}</h4>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    image: "Finnovex-West-Africa-2024.png",
    link: "https://wa.finnovex.com",
    date: "25th February 2025",
    location: "Lagos, Nigeria",
  },
  {
    image: "Finnovex-North-Africa-2024.png",
    link: "https://na.finnovex.com",
    date: "23rd April 2025",
    location: "Cairo, Egypt",
  },
  {
    image: "Finnovex-Saudi-Arabia-2024.png",
    link: "https://ksa.finnovex.com",
    date: "6th-7th May 2025",
    location: "Riyadh, Saudi Arabia",
  },
  {
    image: "Finnovex-Qatar-2024.png",
    link: "https://qa.finnovex.com",
    date: "24th June 2025",
    location: "Doha, Qatar",
  },
  {
    image: "Finnovex-South-Africa-2024.png",
    link: "https://sa.finnovex.com",
    date: "22nd-23rd July 2025",
    location: "Johannesburg, South Africa",
  },
  {
    image: "Finnovex-Middle-East-2024.png",
    link: "https://me.finnovex.com",
    date: "9th-10th September 2025",
    location: "Dubai, UAE",
  },

  {
    image: "Finnovex-East-Africa-2024.png",
    link: "https://ea.finnovex.com",
    date: "7th October 2025",
    location: "Rwanda, East Africa",
  },
  // { image: "Finnovex-Europe-2024.png", link: "https://eu.finnovex.com" },
];

export default Stats;
